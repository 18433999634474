import React, { useCallback } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

const ShowSelection = ({ formData, setFormData, company }) => {
    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }, []);
    return (
        <>
            <Row className="mt-3 gy-2">
                <Col xs={12} lg={6}>
                    <Form.Group controlId="formFtd">
                        <Form.Label className="text-secondary">Select Main Event</Form.Label>
                        <Form.Select
                            as="select"
                            name="ftd"
                            value={formData?.ftd}
                            onChange={handleInputChange}
                        >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group controlId="formAppId">
                        <Form.Label className="text-secondary">Show AppId</Form.Label>
                        <Form.Select
                            as="select"
                            name="appId"
                            value={formData?.appId}
                            onChange={handleInputChange}
                        >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mt-3 gy-2">
                <Col xs={12} lg={6}>
                    <Form.Group controlId="formshowappName">
                        <Form.Label className="text-secondary">Show App Name</Form.Label>
                        <Form.Select
                            as="select"
                            name="appName"
                            value={formData?.appName}
                            onChange={handleInputChange}
                        >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group controlId="formshowcompaignFilter">
                        <Form.Label className="text-secondary">Show Campaign Filter</Form.Label>
                        <Form.Select
                            as="select"
                            name="showCompaignFilter"
                            value={formData?.showCompaignFilter}
                            onChange={handleInputChange}
                        >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mt-3 gy-2">
                <Col xs={12} lg={6}>
                    <Form.Group controlId="formAppId">
                        <Form.Label className="text-secondary">Show Overview Per Day</Form.Label>
                        <Form.Select
                            as="select"
                            name="overviewPerDay"
                            value={formData?.overviewPerDay}
                            onChange={handleInputChange}
                        >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group controlId="formshowExportTable">
                        <Form.Label className="text-secondary">Show Exporting table</Form.Label>
                        <Form.Select
                            as="select"
                            name="showExportTable"
                            value={formData?.showExportTable}
                            onChange={handleInputChange}
                        >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mt-3 gy-2">
                <Col xs={12} lg={6}>
                    <Form.Group controlId="formextraColumnPlace">
                        <Form.Label className="text-secondary">Show Extra columns Before Main Event</Form.Label>
                        <Form.Select
                            as="select"
                            name="extraColumnPlace"
                            value={formData?.extraColumnPlace}
                            onChange={handleInputChange}
                        >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group controlId="formshowExport">
                        <Form.Label className="text-secondary">Show Export Pdf</Form.Label>
                        <Form.Select
                            as="select"
                            name="showExport"
                            value={formData?.showExport}
                            onChange={handleInputChange}
                        >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mt-3 gy-2">
                <Col xs={12} lg={6}>
                    <Form.Group controlId="formshowgeo">
                        <Form.Label className="text-secondary">Show Geo's Tab</Form.Label>
                        <Form.Select
                            as="select"
                            name="showgeo"
                            value={formData?.showgeo}
                            onChange={handleInputChange}
                        >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group controlId="formcityFranceMap">
                        <Form.Label className="text-secondary">Show Map</Form.Label>
                        <Form.Select
                            as="select"
                            name="cityFranceMap"
                            value={formData?.cityFranceMap}
                            onChange={handleInputChange}
                        >
                            <option value={'worldwide'}>Worldwide</option>
                            <option value={'FR'}>France</option>
                            <option value={'US'}>US</option>
                            <option value={'UK'}>UK</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mt-3 gy-2">
                <Col xs={12} lg={6}>
                    <Form.Group controlId="creativelabel">
                        <Form.Label className="text-secondary">Creative Column name</Form.Label>
                        <Form.Control
                            type="text"
                            name="creativelabel"
                            value={formData?.creativeColumn || ''}
                            onChange={(e) => setFormData((prev) => ({
                                ...prev,
                                creativeColumn: e.target.value.trim()
                            }))}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group controlId="formcreativeFtd">
                        <Form.Label className="text-secondary">Use Main Event's(sum) in creative</Form.Label>
                        <Form.Select
                            as="select"
                            name="creativeFtd"
                            value={formData?.creativeFtd}
                            onChange={handleInputChange}
                        >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}

export default ShowSelection
